import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { PathSummary } from '@app/modules/location/models/path-api.model';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { AssetIoService } from '@app/modules/asset-io/asset-io.service';

@Component({
  selector: 'app-asset-io-summary',
  templateUrl: './asset-io-summary.component.html',
  styleUrls: ['./asset-io-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetIoSummaryComponent implements OnInit {
  @Input() summary: PathSummary;

  displayedIos: boolean[];
  totalIoCount: number;
  isExpanded = false;

  constructor(
    private locationFacade: LocationFacade,
    private assetIoService: AssetIoService,
    private changeDetectorRef: ChangeDetectorRef,
    public translateService: TranslateService,
    public translations: Translations
  ) {}

  ngOnInit() {
    this.displayedIos = this.summary.ioCounts.map(() => false); // initially toggled off
    this.totalIoCount = this.getTotalIoCount();
  }

  getTotalIoCount(): number {
    return this.summary.ioCounts.reduce((acc, cur) => acc + cur.count, 0);
  }

  getActiveIos() {
    return this.summary.ioCounts
      .map((io, i) => ({ label: `${io.label} (IO ${i + 1})`, count: io.count, i }))
      .filter(io => io.count > 0);
  }

  toggleIo(i: number): void {
    this.displayedIos[i] = !this.displayedIos[i];
    this.locationFacade.toggleIo(i, this.displayedIos[i]);
    this.changeDetectorRef.detectChanges();
  }

  onExpansionClick() {
    this.isExpanded = !this.isExpanded;
  }
}
