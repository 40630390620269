import { Component, Input, OnInit, ViewChild, TemplateRef, ViewContainerRef, DoCheck } from '@angular/core';
import { OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { OverlayService } from '@app/modules/location/services/overlay.service';

// original code taken from https://stackblitz.com/edit/overlay-progress-spinner

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit, DoCheck {
  @Input() backdropEnabled = false;
  @Input() backdropClass = 'overlay-backdrop';
  @Input() positionGloballyCenter = true;
  @Input() blockScrolling = false;
  @Input() displayOverlay = false;

  @ViewChild('loadingSpinnerRef')
  private loadingSpinnerRef: TemplateRef<any>;
  private loadingSpinnerOverlayConfig: OverlayConfig;
  private overlayRef: OverlayRef;

  constructor(private vcRef: ViewContainerRef, private overlayService: OverlayService) {}

  ngOnInit() {
    // Config for Overlay Service
    this.loadingSpinnerOverlayConfig = {
      hasBackdrop: this.backdropEnabled
    };
    if (this.backdropEnabled && this.backdropClass) {
      this.loadingSpinnerOverlayConfig.backdropClass = this.backdropClass;
    }
    if (this.blockScrolling) {
      this.loadingSpinnerOverlayConfig.scrollStrategy = this.overlayService.blockScrollStrategy();
    }
    if (this.positionGloballyCenter) {
      this.loadingSpinnerOverlayConfig.positionStrategy = this.overlayService.positionGloballyCenter();
    }

    // Create Overlay for loading spinner
    this.overlayRef = this.overlayService.createOverlay(this.loadingSpinnerOverlayConfig);
  }

  ngDoCheck() {
    // Based on status of displayLoadingSpinner attach/detach overlay to loading spinner template
    if (this.displayOverlay && !this.overlayRef.hasAttached() && this.loadingSpinnerRef) {
      this.overlayService.attachTemplatePortal(this.overlayRef, this.loadingSpinnerRef, this.vcRef);
    } else if (!this.displayOverlay && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
