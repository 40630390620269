<div class="asset-list-container nearby-asset-list-container _pendo-nearby">
    
    <div *ngIf="!findNearbyAssets else displayNearbyAssets" class="button-container">
        <button
            (click)="onButtonClick()"
            mat-stroked-button
            color="accent"
            class="find-nearby-assets-button _pendo-find-nearby-button"
        >{{ translateService.get(translations.appNearbyAssetsList.findNearbyAssets) | async | translate }}</button>
    </div>
    
    <ng-template #displayNearbyAssets>
        <app-progress-spinner  *ngIf="isLoading$ | async else loaded"></app-progress-spinner>
        <ng-template #loaded>
            <h3 class="asset-list-title">{{ translateService.get(translations.appNearbyAssetsList.nearbyAssets) | async | translate }}</h3>
                <app-asset-list-item *ngFor="let asset of nearbyAssets"
                    [asset]="asset"
                    (hoverOver)="assetDetailsFacade.setHover(asset)"
                    (hoverOut)="assetDetailsFacade.clearHover()"
                    (itemClick)="setSelectedAsset(asset)"
                    class="_pendo-nearby-asset-item"
                ></app-asset-list-item>
                <div *ngIf="nearbyAssets?.length === 0" class="no-nearby-assets-message-container mat-caption">
                    {{translateService.get(translations.appNearbyAssetsList.noAssetsPt1) | async | translate}} {{searchRadiusDistance}} {{ translateService.get(translations.appNearbyAssetsList.noAssetsPt2) | async | translate }}.
                </div>
        </ng-template>
    </ng-template>
</div>
