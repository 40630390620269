/// <reference types='leaflet.locatecontrol' />
/// <reference types='@runette/leaflet-fullscreen' />

import { Injectable, OnDestroy } from '@angular/core';
import { LeafletMarkerAsset } from '@app/modules/location/models/leaflet-marker.asset';
import { marker } from 'leaflet';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import * as utils from '@app/modules/shared/utilities/utilities';
import { DeviceType, EventSeverity } from '@app/modules/location-client/location-api.models';
import { FeatureToggleService } from '@app/modules/feature-toggles/services/feature-toggle.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UiUtilities } from '@app/services/ui-utilities';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { EnvironmentService } from '@app/services/environment.service';
import { getDriverName } from '@app/modules/shared/utilities/utilities';

declare var L; // eslint-disable-line no-var

@Injectable()
export class MarkerIconService implements OnDestroy {
  featureEnabled;
  mapsAssetLabelDriverNameFeatureEnabled;
  onDestroy$ = new Subject<void>();
  translated;

  constructor(
    private envService: EnvironmentService,
    private featureToggleService: FeatureToggleService,
    private translateService: TranslateService,
    private translations: Translations,
    private uiUtils: UiUtilities
  ) {
    this.featureToggleService
      .isFeatureEnabled('voltron-live-enabled')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(flag => (this.featureEnabled = flag));
    this.featureToggleService
      .isFeatureEnabled('maps-asset-label-driver-name')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(flag => (this.mapsAssetLabelDriverNameFeatureEnabled = flag));

    this.translations.translationsLoadState
      .pipe(filter(loadstate => loadstate === ResourceLoadState.LOAD_SUCCESSFUL))
      .subscribe(() => {
        this.translated = this.translateService.instant([
          this.translations.map.markerIconService.altNoLocation,
          this.translations.map.markerIconService.altZtrak,
          this.translations.map.markerIconService.altIsPoweredOff,
          this.translations.map.markerIconService.altIsIdling,
          this.translations.map.markerIconService.altConnectionError,
          this.translations.map.markerIconService.altIsMoving
        ]);
      });
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private compassDirections = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW'
  ];
  private headingsLabels = [
    '0',
    '225',
    '450',
    '675',
    '900',
    '1125',
    '1350',
    '1575',
    '1800',
    '2025',
    '2250',
    '2475',
    '2700',
    '2925',
    '3150',
    '3375'
  ];
  private mixinDirections = this.compassDirections.reduce((a, c, i) => ({ ...a, [c]: this.headingsLabels[i] }), {});

  private mapMarkerIcon = (
    iconUrl,
    iconTitle,
    iconSubtitle,
    assetId,
    altText,
    direction?,
    highlight?,
    additionalClassNames: string = null,
    hasSevereEvent: boolean = false,
    markerIconOffset?
  ) => {
    let iconClass = 'map-marker-icon';
    let containerClass = 'map-marker';
    if (highlight) {
      containerClass += ' highlight';
    }

    if (direction) {
      iconClass += ` deg-${this.mixinDirections[direction]}`;
    }

    if (additionalClassNames) {
      containerClass += ` ${additionalClassNames}`;
    }

    const iconTitleHtml = iconTitle ? `<div class="map-marker-label">${iconTitle}</div>` : '';
    const iconSubtitleHtml = iconSubtitle ? `<div class="map-marker-subtitle">${iconSubtitle}</div>` : '';
    const mapMarkerAlertIconHtml = hasSevereEvent
      ? '<img class="map-marker-alert-icon" src="assets/leaflet/mapwarning.svg" />'
      : '';
    const html = `
      <div class="map-marker-container map-marker-reposition-${markerIconOffset}" data-cy="asset-pin" data-asset-id="${assetId}">
        <img class="${iconClass}" src="${iconUrl}" alt="${altText}"/>
        <div class="map-marker-label-container">
          ${iconTitleHtml}
          ${iconSubtitleHtml}
        </div>
        ${mapMarkerAlertIconHtml}
      </div>
    `;

    return new L.divIcon({
      className: containerClass,
      html
    });
  };

  private _fetchAssetsResource(
    asset: ViewableAsset,
    resource: string,
    highlight?: boolean,
    label: string = null,
    additionalClassNames: string = null,
    markerIconOffset?: string
  ) {
    const assetSubTitle = asset.markerSubtitle;
    const assetGpsType = this.uiUtils.gpssnToGpsType(asset?.gpssn);
    const { assetId } = asset;
    const hasSevereEvent = this.featureEnabled && asset.openEvents?.some(e => e.severity == EventSeverity.CRITICAL);
    const driverName = this.mapsAssetLabelDriverNameFeatureEnabled ? getDriverName(asset) : null;

    const assetLabel = driverName ? `${label} | ${driverName}` : label;
    if (!utils.validGpsData(asset)) {
      return resource === 'markerIcon'
        ? this.mapMarkerIcon(
            LeafletMarkerAsset.GREY_DOT,
            assetLabel,
            assetSubTitle,
            assetId,
            `${asset.assetName} ${this.translated[this.translations.map.markerIconService.altNoLocation]}`,
            0,
            highlight,
            additionalClassNames,
            hasSevereEvent
          )
        : LeafletMarkerAsset.GREY_DOT;
    }

    if (assetGpsType === DeviceType.ZTRAK) {
      return resource === 'markerIcon'
        ? this.mapMarkerIcon(
            LeafletMarkerAsset.GREY_DOT,
            assetLabel,
            assetSubTitle,
            assetId,
            `${asset.assetName} ${this.translated[this.translations.map.markerIconService.altZtrak]}`,
            0,
            highlight,
            additionalClassNames,
            hasSevereEvent
          )
        : LeafletMarkerAsset.GREY_DOT;
    }
    if (!asset.powerOn) {
      return resource === 'markerIcon'
        ? this.mapMarkerIcon(
            LeafletMarkerAsset.POWERED_OFF,
            assetLabel,
            assetSubTitle,
            assetId,
            `${asset.assetName} ${this.translated[this.translations.map.markerIconService.altIsPoweredOff]}`,
            0,
            highlight,
            additionalClassNames,
            hasSevereEvent
          )
        : LeafletMarkerAsset.POWERED_OFF;
    }
    if (asset.powerOn && asset.idling && !asset.connectionIssue) {
      return resource === 'markerIcon'
        ? this.mapMarkerIcon(
            LeafletMarkerAsset.IDLING,
            assetLabel,
            assetSubTitle,
            assetId,
            `${asset.assetName} ${this.translated[this.translations.map.markerIconService.altIsIdling]}`,
            0,
            highlight,
            additionalClassNames,
            hasSevereEvent
          )
        : LeafletMarkerAsset.IDLING;
    }
    if (asset.connectionIssue) {
      return resource === 'markerIcon'
        ? this.mapMarkerIcon(
            LeafletMarkerAsset.CONNECTION_ERROR,
            assetLabel,
            assetSubTitle,
            assetId,
            `${this.translated[this.translations.map.markerIconService.altConnectionError]}`,
            0,
            highlight,
            additionalClassNames,
            hasSevereEvent
          )
        : LeafletMarkerAsset.CONNECTION_ERROR;
    }
    // powered on and not idling and no connection issue, call it in motion
    const dir = this.uiUtils.degToCompass(asset.heading);
    return resource === 'markerIcon'
      ? this.mapMarkerIcon(
          this.envService.getEnvironment()?.region !== utils.EUROPE
            ? LeafletMarkerAsset.IN_MOTION
            : LeafletMarkerAsset.IN_MOTION_VDO,
          assetLabel,
          assetSubTitle,
          assetId,
          `${asset.assetName} ${this.translated[this.translations.map.markerIconService.altIsMoving]} ${dir}`,
          dir,
          highlight,
          additionalClassNames,
          hasSevereEvent,
          markerIconOffset
        )
      : this.envService.getEnvironment()?.region !== utils.EUROPE
      ? LeafletMarkerAsset.IN_MOTION
      : LeafletMarkerAsset.IN_MOTION_VDO;
  }

  fetchAssetClassName(asset: ViewableAsset) {
    if (asset.powerOn && !asset.idling) {
      return `map-marker-icon deg-${this.mixinDirections[this.uiUtils.degToCompass(asset.heading)]}`;
    }
    return 'map-marker-icon';
  }

  fetchMarkerIcon(
    asset: ViewableAsset,
    highlight?: boolean,
    label = null,
    additionalClassNames: string = null,
    positionMultiplier?: number
  ) {
    const markerIconOffset = positionMultiplier
      ? String(Math.round(asset.assetName.split('').length / positionMultiplier))
      : '0';
    return this._fetchAssetsResource(asset, 'markerIcon', highlight, label, additionalClassNames, markerIconOffset);
  }

  fetchAssetsListIconUrl(asset: ViewableAsset) {
    return this._fetchAssetsResource(asset, 'assetIcon');
  }

  fetchGeoJsonMarker(f, latlng, label = null, multiplier?) {
    const additionalClassNames = f.properties.distanceFromSelectedAsset ? '_pendo-nearby-marker' : null;

    // Properties are the asset object itself, so this seems to make sense.
    return marker(latlng, { icon: this.fetchMarkerIcon(f.properties, null, label, additionalClassNames, multiplier) });
  }
}
