<div class="expanding-details-container">
  <div fxLayout="row">
    <button
    mat-button
    class="_pendo-view-more"
    (click)="onExpansionClick()"
    fxFill>
      <div fxFlex fxLayoutAlign="start center">
        <zui-icon [icon]="icon" class="activity-icon" ></zui-icon>
        <p class="activity-value">{{ label | truncate: 16 }}</p>
        <ng-container *ngIf="showTooltip"
        >
          <zui-icon
            class="tooltip-icon"
            [icon]="'info_outline'"
            [matTooltip]="tooltipText"
            [matTooltipPosition]="'right'"
          ></zui-icon>
        </ng-container>

      </div>
      <mat-icon *ngIf="isAnyDisplayableField" class="expand-icon" fxFlex="10" fxLayoutAlign="end center">{{expansionIcon}}</mat-icon>
    </button>
  </div>
  <div *ngIf="isExpanded" class="expansion-content" [class.bottom-border]="bottomBorderOnExpanded" fxLayout="row" fxFill>
    <ng-content></ng-content>
  </div>
</div>
