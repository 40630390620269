import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { environment, environment as env } from '@environments/environment';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { switchMap, takeUntil, withLatestFrom, filter, take, tap, map, combineLatestWith } from 'rxjs/operators';
import { getDistance, getDriverName, validGpsData } from '@app/modules/shared/utilities/utilities';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { UiUtilities } from '@app/services/ui-utilities';
import { LocationApiService } from '@app/modules/location-client/location-api.service';
import { MarkerIconService } from '@app/modules/location/services/marker-icon.service';
import { Asset, PostBodyConfig } from '@app/modules/location-client/location-api.models';
import { Router } from '@angular/router';
import { AssetDetailsFacade } from '@app/modules/asset-details-live-container/facade/asset-details-facade.service';

@Component({
  selector: 'app-nearby-assets-list',
  templateUrl: './nearby-assets-list.component.html',
  styleUrls: ['./nearby-assets-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NearbyAssetsListComponent implements OnInit, OnDestroy {
  constructor(
    private locationApiService: LocationApiService,
    public assetDetailsFacade: AssetDetailsFacade,
    public markerIconService: MarkerIconService,
    private changeDetector: ChangeDetectorRef,
    public translateService: TranslateService,
    public translations: Translations,
    private uiUtils: UiUtilities,
    public router: Router
  ) {}

  @Input() selectedAsset: ViewableAsset;
  @Output() nearbyAssetSelected: EventEmitter<void> = new EventEmitter<void>();

  companyId: string = null;
  postBodyConfig$ = new BehaviorSubject<PostBodyConfig>({ companyId: this.companyId });
  pollingCanceller$ = new Subject<Boolean>();
  pollingInterval$ = new BehaviorSubject<number>(environment.liveUpdate.pollingInterval);

  translated;
  onDestroy$ = new Subject<void>();
  findNearbyAssets = false;
  nearbyAssets: ViewableAsset[];
  searchRadiusDistance = this.uiUtils.formatDistance(env.nearbyAssets.searchRadiusMeters);
  isLoading$ = new BehaviorSubject<boolean>(false);

  ngOnInit() {
    this.postBodyConfig$
      .pipe(
        filter(pbc => pbc.companyId != null),
        combineLatestWith(this.pollingInterval$),
        tap(() => this.pollingCanceller$.next(true)),
        switchMap(([p, interval]) =>
          this.locationApiService.polling<Asset[]>(
            () =>
              this.locationApiService.getAssetsByRadius([this.selectedAsset.longitude, this.selectedAsset.latitude], p),
            interval,
            this.pollingCanceller$
          )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(assets => {
        this.isLoading$.next(false);
        this.nearbyAssets = this.addMarkerDataToNearbyAssets(assets as ViewableAsset[], this.selectedAsset);
        this.assetDetailsFacade.showNearbyAssets();
        if (this.nearbyAssets?.length) {
          // I'm passing nearby assets as regular assets to refreshMap as a means of disconnecting
          // from locationFacade.getAllAssets() which depends on state.
          const mappedAssets = this.nearbyAssets.concat([this.selectedAsset]);
          this.assetDetailsFacade.refreshMap(mappedAssets, [], this.selectedAsset);
          this.assetDetailsFacade.zoomToAssets(mappedAssets);
          this.changeDetector.detectChanges();
        } else {
          this.changeDetector.detectChanges();
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getNearbyAssets() {
    this.postBodyConfig$.pipe(take(1)).subscribe(pbc => {
      this.isLoading$.next(true);
      const newPbc = {
        ...pbc,
        companyId: this.selectedAsset.companyId
      };
      this.postBodyConfig$.next(newPbc);
    });
  }

  addMarkerDataToNearbyAssets(nearbyAssets: ViewableAsset[], selectedAsset: ViewableAsset): ViewableAsset[] {
    const fromLatLng = [selectedAsset?.latitude, selectedAsset?.longitude];
    return nearbyAssets
      ?.filter(asset => asset.assetId !== selectedAsset.assetId)
      ?.map(asset => {
        const distance = this.uiUtils.convertDistance(getDistance(fromLatLng, [asset.latitude, asset.longitude]));
        return {
          ...asset,
          distanceFromSelectedAsset: distance,
          iconUrl: this.markerIconService.fetchAssetsListIconUrl(asset),
          className: this.markerIconService.fetchAssetClassName(asset),
          subTitle: this.uiUtils.assetSubtitle(asset),
          sidebarMessage: this.uiUtils.assetSidebarMessage(distance),
          markerSubtitle: this.uiUtils.assetSidebarMessage(distance)
        };
      })
      .sort((a, b) => a.distanceFromSelectedAsset - b.distanceFromSelectedAsset);
  }

  setSelectedAsset(asset) {
    this.pollingCanceller$.next(true);
    this.findNearbyAssets = false;
    this.router.navigate([`/assets/${asset.assetId}/live`]);
  }

  onButtonClick() {
    this.findNearbyAssets = true;
    this.getNearbyAssets();
  }
}
