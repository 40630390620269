import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { GTCxEventData } from '@app/modules/location-client/location-api.models';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { EventDetails } from '@app/modules/events/events.model';
import { EventsService } from '@app/modules/events/events.service';
import { FeatureToggleService } from '@app/modules/feature-toggles/services/feature-toggle.service';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { AssetDetailsFacade } from '@app/modules/asset-details-live-container/facade/asset-details-facade.service';
import { ViewContext } from '@app/store/layout/reducers/layout.reducer';

@Component({
  selector: 'app-open-events',
  templateUrl: './open-events-container.component.html',
  styleUrls: ['./open-events-container.component.scss']
})
export class OpenEventsContainerComponent implements OnInit, OnDestroy, OnChanges {
  onDestroy$ = new Subject<void>();
  isExpanded = false;
  assetOpenEvents: GTCxEventData[];
  eventDetails: EventDetails[];
  showEvents = false;
  showEventsInDetailsView: boolean;
  skeletonLength = 3;
  LOADING = ResourceLoadState.LOADING;
  FAILURE = ResourceLoadState.LOAD_FAILURE;
  eventsHaveBeenFetched = false;

  @Input() asset: ViewableAsset;
  @Output() hasEvents = new EventEmitter<boolean>();

  constructor(
    public eventsService: EventsService,
    public featureToggleService: FeatureToggleService,
    public assetDetailsFacade: AssetDetailsFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.assetDetailsFacade
      .getIsMobile()
      .pipe(take(1))
      .subscribe(isMobile => {
        if (isMobile) {
          this.assetDetailsFacade
            .getSelectViewContext()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(context => {
              this.showEventsInDetailsView = context === ViewContext.DETAILS;
            });
        } else {
          this.showEventsInDetailsView = true;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.asset = changes.asset.currentValue;
    this.showEvents = Boolean(this.asset?.openEvents && this.asset?.openEvents.length);
    this.changeDetectorRef.detectChanges();
    this.fetchAssetOpenEvents(this.asset);
  }

  fetchAssetOpenEvents(asset) {
    if (!asset) {
      return;
    }
    this.eventsService
      .fetchOpenEventsForAsset(this.asset.assetId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(events => {
        this.assetOpenEvents = events;
        this.eventDetails = this.eventsService.getOpenEventsDetails(this.assetOpenEvents);
        this.hasEvents.emit(this.eventDetails.length >= 1);
        this.eventsHaveBeenFetched = true;
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
