import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Control, ControlPosition, DomUtil, Map } from 'leaflet';
import { Subject } from 'rxjs';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { WeatherLayer, WeatherLayerChange } from '@app/modules/location/services/weather-layer.service';

@Component({
  selector: 'app-map-options',
  templateUrl: './map-options.component.html',
  styleUrls: ['./map-options.component.scss']
})
export class MapOptionsComponent implements OnDestroy {
  private _map: Map;
  mapOptionsControl: Control;
  onDestroy$ = new Subject<void>();
  @Output() toggleMapOptionsMenuEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() position: ControlPosition;
  @Input() isMapOptionsMenuOpen: boolean = false;
  @Input() checkboxesVisible: boolean = false;

  @Input() layer: string = 'NORMAL_DAY';
  @Output() layerChange: EventEmitter<string> = new EventEmitter();

  @Input() clusterEnabled: boolean = false;
  @Output() clusterEnabledChange: EventEmitter<boolean> = new EventEmitter();

  @Input() incidentsEnabled: boolean = false;
  @Output() incidentsEnabledChange: EventEmitter<boolean> = new EventEmitter();

  @Input() trafficEnabled: boolean = false;
  @Output() trafficEnabledChange: EventEmitter<boolean> = new EventEmitter();

  @Input() zonesEnabled: boolean = false;
  @Output() zonesEnabledChange: EventEmitter<boolean> = new EventEmitter();

  @Input() weatherLayers: WeatherLayer[];
  @Output() weatherLayerChange: EventEmitter<WeatherLayerChange> = new EventEmitter();

  @Input() classicLayerSelected: boolean = false;
  @Input() satelliteLayerSelected: boolean = false;
  @Input()
  set map(map: Map) {
    if (map) {
      this._map = map;
      const MapOptionsControl = Control.extend({
        onAdd(map: Map) {
          return DomUtil.get('mapOptionsControl');
        },
        onRemove(map: Map) {}
      });

      this.mapOptionsControl = new MapOptionsControl({
        position: this.position
      }).addTo(map);
    }
  }

  get map(): Map {
    return this._map;
  }

  constructor(public translateService: TranslateService, public translations: Translations) {}

  ngOnDestroy() {
    this._map?.removeControl(this.mapOptionsControl);
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  toggleMapOptionsMenu() {
    this.isMapOptionsMenuOpen = !this.isMapOptionsMenuOpen;
    this.toggleMapOptionsMenuEvent.emit(this.isMapOptionsMenuOpen);
  }

  onLayerChange(event: string) {
    this.layer = event;
    this.layerChange.emit(this.layer);
  }

  onClusterEnabledChange(event: boolean) {
    this.clusterEnabledChange.emit(event);
  }

  onIncidentsEnabledChange(event: boolean) {
    this.incidentsEnabledChange.emit(event);
  }

  onTrafficEnabledChange(event: boolean) {
    this.trafficEnabledChange.emit(event);
  }

  onZonesEnabledChange(event: boolean) {
    this.zonesEnabledChange.emit(event);
  }

  onWeatherLayerChange(event: WeatherLayerChange) {
    this.weatherLayerChange.emit(event);
  }
}
