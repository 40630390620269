// Angular
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DataDogService } from '@app/services/data-dog.service';
import { Router } from '@angular/router';

// RxJS
import { Subject } from 'rxjs';
import { distinctUntilKeyChanged, filter, map, take, takeUntil } from 'rxjs/operators';

// App
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { EntityType, FilterArgs } from '@app/store/filters/models/filters.model';
import { PlatformFacade } from '@app/modules/platform/facade/platform.facade';
import { AutocompleteFacade } from '@app/modules/location/facade/autocomplete.facade';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { Asset } from '@app/modules/location-client/location-api.models';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';

export enum OptionIcons {
  ASSET = 'local_shipping',
  DRIVER = 'person',
  SEARCH = 'search'
}

@Component({
  selector: 'app-asset-search-form',
  templateUrl: './asset-search-form.component.html',
  styleUrls: ['./asset-search-form.component.scss']
})
export class AssetSearchFormComponent implements OnInit, OnDestroy {
  constructor(
    private autocompleteFacade: AutocompleteFacade,
    private changeDetectorRef: ChangeDetectorRef,
    public locationFacade: LocationFacade,
    public platformFacade: PlatformFacade,
    private dataDog: DataDogService,
    private router: Router,
    public translateService: TranslateService,
    public translations: Translations
  ) {}

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  @Output() filterClick = new EventEmitter<boolean>();

  autocompleteMinQueryLength = 2;
  @Input() isAssetSelected: boolean;
  onDestroy$ = new Subject();
  searchInput = new UntypedFormControl();
  showClearBtn = false;
  assets: Asset[];
  @Input() hideFilterButton: boolean;

  options$ = this.autocompleteFacade.getAutocompleteResults().pipe(
    map(autocompleteState => {
      const { assets, drivers } = autocompleteState;
      const searchValue = this.searchInput.value;

      const formattedAssets = (assets || []).map(asset => ({
        ...asset,
        value: asset.assetName,
        html: this.createOptionHtml(OptionIcons.ASSET, asset.assetName, searchValue)
      }));

      const formattedDrivers = (drivers || []).map(driver => {
        const fullName = `${driver.driverFirstName} ${driver.driverLastName}`;

        return {
          ...driver,
          value: fullName,
          html: this.createOptionHtml(OptionIcons.DRIVER, fullName, searchValue)
        };
      });

      const autocompleteOptions = [...formattedAssets, ...formattedDrivers];

      // this allows users to select "All" in the autocomplete options dropdown, which does a fuzzy search for the current search value
      if (searchValue?.length >= 2) {
        autocompleteOptions.push(this.createViewAllOptionHtml(searchValue));
      }

      return autocompleteOptions;
    }),
    takeUntil(this.onDestroy$)
  );

  ngOnInit(): void {
    // this subscription listens for company changes and resets the searchbox if there is a query in it
    this.getCurrentCompany().subscribe(company => {
      if (this.searchInput?.value) {
        this.searchInput.reset();
      }
    });

    this.locationFacade.getCurrentFilter().subscribe(() => {
      this.searchInput.setValue('', { emitEvent: false });
    });

    this.searchInput.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      this.showClearBtn = Boolean(value);
      if (value?.length >= this.autocompleteMinQueryLength) {
        this.autocompleteFacade.search(value);
      } else {
        this.autocompleteFacade.clearAutocompleteResults();
      }
    });

    this.locationFacade
      .getSelectedViewPaneOpen()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(viewPaneOpen => {
        if (viewPaneOpen) {
          this.autocomplete?.openPanel();
        } else {
          this.autocomplete?.closePanel();
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  // ZTT-3664 TODO: this should likely take companyId as an input once this is refactored as PL component
  // companyId is available in the filters bar component and can be passed
  getCurrentCompany() {
    return this.locationFacade.getAllFilters().pipe(
      map(filters => {
        return filters.filter.company;
      }),
      filter(company => !!company),
      distinctUntilKeyChanged('id'),
      takeUntil(this.onDestroy$)
    );
  }
  createOptionHtml(icon: string, value: string, searchValue: string): string {
    const iconSpan = `<span class="material-icons">${icon}</span>`;
    const regex = new RegExp(searchValue, 'ig');
    const valueHtml = value.replace(regex, match => `<strong>${match}</strong>`);
    // this set of <pre> tags is needed to preserve whitespace
    return `${iconSpan}<pre>${valueHtml}</pre>`;
  }

  createViewAllOptionHtml(searchValue: string) {
    return {
      assetId: '',
      assetName: '',
      active: false,
      hasAssociatedDevice: false,
      companyId: '',
      divisionId: '',
      value: 'ALL',
      html: this.createOptionHtml(OptionIcons.SEARCH, `Show All for ${searchValue}`, searchValue)
    };
  }

  resetSearchInput() {
    this.searchInput.reset();
    this.autocompleteFacade.clearAutocompleteResults();
  }

  handleFilter() {
    this.filterClick.emit(true);
  }

  navigateToSelectedAssetView(assetId: string) {
    this.locationFacade.clearClusterLayer();
    this.router.navigate(['/assets', assetId, 'live']);
  }

  handleSelectionChange(option) {
    const { assetId, driverProfileId, driverFirstName, driverLastName, value } = option;
    if (value === 'ALL') {
      this.searchAssets(this.searchInput?.value);
    } else if (assetId) {
      this.navigateToSelectedAssetView(assetId);
    } else {
      const driver = {
        id: driverProfileId,
        name: `${driverFirstName} ${driverLastName}`,
        type: EntityType.DRIVER
      };
      this.setSelectedDriver(driver);
    }
  }

  searchAssets(searchValue: string) {
    if (searchValue.length > 1) {
      searchValue = searchValue.trim();
      this.autocomplete.closePanel();
      this.autocompleteFacade.clearAutocompleteResults();
      let currentFilter;
      this.locationFacade
        .getAllFilters()
        .pipe(take(1))
        .subscribe(filters => {
          currentFilter = filters.filter;
        });
      // TODO: applyFilters needs to be refactored so that only the filter attrs that are changing are required
      // right now need to build the filterArgs from filters.filter each time
      const newFilterArgs = <FilterArgs>{
        company: currentFilter.company.id,
        divisions: currentFilter.divisions.map(d => d.id),
        locations: currentFilter.locations.map(l => l.id),
        powerOn: currentFilter.powerOn,
        searchTerms: [...currentFilter.searchTerms, searchValue]
      };
      this.resetSearchInput();
      this.locationFacade.applyFilters(newFilterArgs);
      this.dataDog.addRumAction('fuzzy_search', { searchTerm: searchValue });
    }
  }

  setSelectedDriver(driver) {
    this.searchInput.reset();
    this.autocompleteFacade.clearAutocompleteResults();
    let currentFilter;
    this.locationFacade
      .getAllFilters()
      .pipe(take(1))
      .subscribe(filters => {
        currentFilter = filters.filter;
      });
    const newFilterArgs = <FilterArgs>{
      powerOn: currentFilter.powerOn,
      company: currentFilter.company?.id,
      divisions: currentFilter.divisions?.map(d => d.id),
      locations: currentFilter.locations?.map(l => l.id),
      driver,
      sortOrder: currentFilter.sortOrder,
      sortAttribute: currentFilter.sortAttribute,
      search: currentFilter.search,
      searchTerms: currentFilter.searchTerms,
      zone: currentFilter.zone,
      divisionIds: currentFilter.divisionIds,
      assetIds: currentFilter.assetIds
    };
    this.locationFacade.applyFilters(newFilterArgs);
  }
}
