import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDriver from '@app/store/driver/reducer/driver.reducer';

export const selectDriverState = createFeatureSelector<fromDriver.DriverState>(fromDriver.driverFeatureKey);

export const selectDriverProfile = createSelector(selectDriverState, state => {
  return state.driverProfile;
});

export const selectDriverProfileLoadState = createSelector(selectDriverState, state => {
  return state.driverProfileLoadState;
});

export const selectDriverAssetProfileLoadState = createSelector(selectDriverState, state => {
  return state.driverAssetProfileLoadState;
});

export const selectDriverAssetProfile = createSelector(selectDriverState, state =>
  state.driverAssetProfile.map(el => {
    return {
      ...el.driverProfile,
      source: el.source,
      sourceLabel: el.sourceLabel,
      startTimestamp: el.startTimestamp,
      endTimestamp: el.endTimestamp
    };
  })
);
