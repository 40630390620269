<div class="open-events-container" *ngIf="showEvents && showEventsInDetailsView">
  <ul *ngIf="!eventsHaveBeenFetched && (eventsService.eventsLoadingStatus$ | async) === LOADING; else details" class="skeleton-list">
    <li *ngFor="let i of [].constructor(skeletonLength)" fxLayoug="row">
      <ngx-skeleton-loader appearance='circle' [theme]="{width: '24px', height: '24px'}" fxFlex="14" fxLayoutAlign="start center"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]='{"margin-bottom": "0px"}' fxFlex fxLayoutAlign="start center"></ngx-skeleton-loader>
    </li>
  </ul>

  <ng-template  #details>
    <ng-container *ngIf="(eventsService.eventsLoadingStatus$ | async) !== FAILURE; else errorMessage">
      <ul class="events">
        <li *ngFor="let eventDetail of eventDetails" fxLayout="column" fxLayoutAlign="start" fxFill >
          <app-expanding-details
            icon="error"
            label="{{ eventDetail?.title | truncate: 28 }}"
            [bottomBorderOnExpanded]="true"
            data-cy="open-event-details"
          >
            <app-expandable-event-details 
              [eventDetails]="eventDetail?.details"
              [timeStamp]="eventDetail?.timeStamp"
            ></app-expandable-event-details>
          </app-expanding-details>
        </li>
      </ul>
    </ng-container>
  </ng-template>

  <ng-template #errorMessage>
    <div class="error-message">
      <p>An error has occurred retrieving event information. Please try again later.</p>
    </div>
  </ng-template>
</div>
