<div fxLayout="row" fxLayoutAlign="start" >
  <div class="asset-io-expanding-container" *ngIf="totalIoCount; else elseBlock">
    <app-expanding-details
      icon="task_alt"
      label="{{totalIoCount}} {{translateService.get(translations.appAssetIoSummary.ioEvents) | async | translate}}"
      [isExpanded]="isExpanded"
      (emitExpansionButtonEvent)="onExpansionClick()" >

      <div class="io-rows-container" fxLayout="column">
        <div *ngFor="let io of getActiveIos()" fxLayout="row" fxLayoutAlign="space-between" class="io-row">
          <span>{{io.label}}</span>
          <zui-icon color="accent" (click)="toggleIo(io.i)" icon="{{displayedIos[io.i] ? 'visibility_off' : 'visibility'}}" class="summary-icon io-icon"></zui-icon>
        </div>
      </div>
    </app-expanding-details>
  </div>
  <ng-template #elseBlock>
    <div fxLayout="row" fxLayoutAlign="start" >
      <zui-icon icon="task_alt" class="summary-icon"></zui-icon>
      <span>{{totalIoCount}} {{translateService.get(translations.appAssetIoSummary.ioEvents) | async | translate}}</span>
    </div>
  </ng-template>
</div>
