import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import {
  Browser,
  Control,
  FitBoundsOptions,
  FullscreenOptions,
  latLng,
  MapOptions,
  point,
  tileLayer,
  TileLayerOptions
} from 'leaflet';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { LocalizationService } from '@app/services/localization.service';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { MAP_OPTIONS_TILE_LAYER_NAMES } from '@app/modules/shared/components/map/controls/map-options-menu/map-options-menu.model';

export interface GTCxTileLayerOptions extends TileLayerOptions {
  name?: string;
}

export const enum LayerSet {
  LIVE = 'LIVE',
  HISTORY = 'HISTORY'
}

export const tileLayerBaseOptions: GTCxTileLayerOptions = {
  opacity: 0.7,
  subdomains: ['1', '2', '3', '4'],
  detectRetina: true,
  id: '',
  minZoom: 2,
  maxZoom: 20
};
@Injectable({
  providedIn: 'root'
})
export class LeafletConfigService {
  constructor(
    private translateService: TranslateService,
    private translations: Translations,
    private localization: LocalizationService
  ) {}

  // ========== Map FitBounds Options ==========
  private mapPadding = {
    default: 100,
    mobileDefault: 10,
    leftDrawerWidth: 368,
    markerLabelWidth: 150
  };

  public defaultBoundingZoom = 16;

  public mobileFitBoundsOptions: FitBoundsOptions = {
    padding: point(this.mapPadding.mobileDefault, this.mapPadding.mobileDefault),
    maxZoom: this.defaultBoundingZoom
  };

  public desktopFitBoundsOptions: FitBoundsOptions = {
    paddingTopLeft: point(this.mapPadding.default + this.mapPadding.leftDrawerWidth, this.mapPadding.default),
    paddingBottomRight: point(this.mapPadding.default + this.mapPadding.markerLabelWidth, this.mapPadding.default),
    maxZoom: this.defaultBoundingZoom
  };
  // ----------------------------------------------

  public defaultFullscreenControlOptions: FullscreenOptions = {
    position: 'bottomright',
    pseudoFullscreen: false,
    title: {
      true: 'Exit Fullscreen',
      false: 'View Fullscreen'
    }
  };

  public defaultLocateControlOptions: Control.LocateOptions = {
    position: 'bottomright',
    flyTo: false,
    keepCurrentZoomLevel: true,
    locateOptions: {
      enableHighAccuracy: true
    },
    icon: 'locate-control',
    markerStyle: {
      zIndexOffset: 10000
    },
    clickBehavior: {
      inView: 'stop',
      outOfView: 'setView',
      inViewNotFollowing: 'setView'
    },
    strings: {
      title: 'Show me where I am'
    }
  };

  private defaultZoomControlOptions: Control.ZoomOptions = {
    position: 'bottomright',
    zoomInTitle: 'Zoom in',
    zoomOutTitle: 'Zoom out'
  };

  public isLayersMenuLoaded$ = new BehaviorSubject(false);

  getLocalizedScaleOptions() {
    const useMetric = this.localization.getUseMetricDistance();
    const scaleOptions: Control.ScaleOptions = {
      maxWidth: 200,
      metric: useMetric,
      imperial: !useMetric,
      updateWhenIdle: true,
      position: 'bottomright'
    };
    return scaleOptions;
  }

  /**
   * Returns an Observable containing options object for various leaflet controls
   * with all strings (ie. button text, tooltips) translated
   * @param control string name of the control for which to return options object
   */
  getTranslatedControlOptions$(control: string): Observable<any> {
    switch (control) {
      case 'attribution':
        return this.translateService.get([this.translations.map.attribution.allRightsReserved]).pipe(
          switchMap(translated =>
            of(
              `&copy; 1987 - ${new Date().getFullYear()} <a href="https://legal.here.com/${
                this.translateService.currentLang
              }/terms" target="_blank">HERE</a>.
                &nbsp${translated[this.translations.map.attribution.allRightsReserved]}`
            )
          )
        );
      case 'wxAttribution':
        return this.translateService.get([this.translations.map.attribution.weatherProvidedBy]).pipe(
          switchMap(translated =>
            of(`${translated[this.translations.map.attribution.weatherProvidedBy]}
              <a href="https:openweathermap.org/" target="_blank">OpenWeather</a>`)
          )
        );
      case 'followAsset':
        return this.translateService
          .get([
            this.translations.map.followAssetControl.followAsset,
            this.translations.map.followAssetControl.followingAsset
          ])
          .pipe(
            switchMap(translated =>
              of({
                followText: translated[this.translations.map.followAssetControl.followAsset],
                followingText: translated[this.translations.map.followAssetControl.followingAsset]
              })
            )
          );
      case 'fullscreen':
        return this.translateService
          .get([
            this.translations.map.fullscreenControl.exitFullscreen,
            this.translations.map.fullscreenControl.viewFullscreen
          ])
          .pipe(
            switchMap(translated =>
              of({
                ...this.defaultFullscreenControlOptions,
                title: {
                  true: translated[this.translations.map.fullscreenControl.exitFullscreen],
                  false: translated[this.translations.map.fullscreenControl.viewFullscreen]
                }
              })
            )
          );
      case 'locate':
        return this.translateService.get([this.translations.map.locateControl.tooltipText]).pipe(
          switchMap(translated =>
            of({
              ...this.defaultLocateControlOptions,
              strings: {
                title: translated[this.translations.map.locateControl.tooltipText]
              }
            })
          )
        );
      case 'viewEntireTrip':
        return this.translateService
          .get([this.translations.map.viewEntireTripControl.viewEntireTrip])
          .pipe(switchMap(translated => of(translated[this.translations.map.viewEntireTripControl.viewEntireTrip])));
      case 'zoom':
        return this.translateService
          .get([this.translations.map.zoomControl.zoomIn, this.translations.map.zoomControl.zoomOut])
          .pipe(
            switchMap(translated =>
              of({
                ...this.defaultZoomControlOptions,
                zoomInTitle: translated[this.translations.map.zoomControl.zoomIn],
                zoomOutTitle: translated[this.translations.map.zoomControl.zoomOut]
              })
            )
          );
      default:
        return of({});
    }
  }

  private hereTileUrl = (scheme: string, resource: string = 'base'): string => {
    const size = 256;
    const ppi = Browser.retina && scheme !== 'explore.satellite.day' ? 400 : 100; // only normal (100 ppi) can be used with satellite.day
    const poiState = 'all';
    const featuresPoiParam = scheme != 'satellite.day' ? `&features=pois:${poiState}` : '';
    const lg = this.localization.getMapTileLanguage();

    const tileUrl = `https://maps.hereapi.com/v3/${resource}/mc/{z}/{x}/{y}/png8?size=${size}&style=${scheme}&ppi=${ppi}${featuresPoiParam}&lang=${lg}&apiKey=${env.here.apiKeyV3}`;
    return tileUrl;
  };

  public tileLayers = {
    NORMAL_DAY: tileLayer(this.hereTileUrl('explore.day'), {
      ...tileLayerBaseOptions,
      name: MAP_OPTIONS_TILE_LAYER_NAMES.CLASSIC
    } as GTCxTileLayerOptions),
    HYBRID_DAY: tileLayer(this.hereTileUrl('explore.satellite.day'), {
      ...tileLayerBaseOptions,
      name: MAP_OPTIONS_TILE_LAYER_NAMES.SATELLITE
    } as GTCxTileLayerOptions)
  };

  public defaultMapOptions: MapOptions = {
    attributionControl: false,
    layers: [this.tileLayers.NORMAL_DAY],
    zoom: 5,
    zoomControl: false,
    zoomSnap: 0.5,
    center: latLng(47.602004, -122.335295),
    // there is a known issue with leaflet version 1.7.1 and mobile safari that causes two click click events to emit.
    // to avoid this issue, we disable tap events entirely, as described in the comments of this issue:
    // https://github.com/Leaflet/Leaflet/issues/7255
    tap: false
  };
}
