import { Component, Input } from '@angular/core';
import { EventMetadata } from '@app/modules/events/events.model';

@Component({
  selector: 'app-expandable-event-details',
  templateUrl: './expandable-event-details.component.html',
  styleUrls: ['./expandable-event-details.component.scss']
})
export class ExpandableEventDetailsComponent {
  @Input() eventDetails: EventMetadata[];
  @Input() timeStamp: string;
}
