import { Injectable } from '@angular/core';
import { TileLayer, tileLayer } from 'leaflet';
import { EnvironmentService } from '@app/services/environment.service';
import { BehaviorSubject } from 'rxjs';

export enum WeatherLayerEnum {
  CLOUDS = 'clouds',
  PRECIP = 'precip',
  TEMP = 'temp',
  WIND = 'wind'
}

export interface WeatherLayerChange {
  layer: WeatherLayerEnum;
  enabled: boolean;
}

export interface WeatherLayer {
  layer: WeatherLayerEnum;
  label: string;
  enabled: boolean;
  tileLayer: TileLayer;
}

@Injectable({
  providedIn: 'root'
})
export class WeatherLayerService {
  constructor(private envService: EnvironmentService) {}

  private _apiKey = this.envService.getEnvironmentProperty('openweathermap').apiKey;
  private _opacity = this.envService.getEnvironmentProperty('openweathermap').opacity ?? 0.8;

  isWeatherLayerEnabled$ = new BehaviorSubject<boolean>(false);

  // 2.0 API
  public weatherLayers: WeatherLayer[] = [
    {
      layer: WeatherLayerEnum.PRECIP,
      label: 'Weather',
      enabled: false,
      tileLayer: this.getWeatherOverlay('PR0') // PRO: Precipitation Intensity
    }
  ];

  getWeatherOverlay(layer: string) {
    const url = `https://maps.openweathermap.org/maps/2.0/weather/${layer}/{z}/{x}/{y}?appId=${this._apiKey}&opacity=${this._opacity}`;
    return tileLayer(url, { maxZoom: 20, detectRetina: true });
  }

  addLayerToMap(layer, map) {
    const idx = this.weatherLayers.findIndex(l => l.layer === layer);
    if (idx > -1) {
      this.weatherLayers[idx].tileLayer.addTo(map);
      this.isWeatherLayerEnabled$.next(true);
    }
  }

  removeLayerFromMap(layer, map) {
    const idx = this.weatherLayers.findIndex(l => l.layer === layer);
    if (idx > -1) {
      this.weatherLayers[idx].tileLayer.removeFrom(map);
      this.isWeatherLayerEnabled$.next(false);
    }
  }

  removeWeatherLayers() {
    for (const layer of this.weatherLayers) {
      layer.tileLayer.remove();
    }
    this.isWeatherLayerEnabled$.next(false);
  }

  enableLayer(layer: string) {
    const idx = this.weatherLayers.findIndex(l => l.layer === layer);
    if (idx >= 0) {
      this.weatherLayers[idx].enabled = true;
    }
    this.isWeatherLayerEnabled$.next(true);
  }

  disableLayer(layer: string) {
    const idx = this.weatherLayers.findIndex(l => l.layer === layer);
    if (idx >= 0) {
      this.weatherLayers[idx].enabled = false;
    }
    this.isWeatherLayerEnabled$.next(false);
  }
}
