import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-expanding-details',
  templateUrl: './expanding-details.component.html',
  styleUrls: ['./expanding-details.component.scss']
})
export class ExpandingDetailsComponent implements OnInit, OnChanges {
  constructor() {}

  @Input() icon: string;
  @Input() label: string;
  @Output() emitExpansionButtonEvent = new EventEmitter<void>();
  @Input() isExpanded = false;
  @Input() bottomBorderOnExpanded = false;
  @Input() showTooltip = false;
  @Input() tooltipText: string;
  @Input() isAnyDisplayableField = true;

  expandMore = 'expand_more';
  expandLess = 'expand_less';
  expansionIcon = this.expandMore;

  ngOnInit() {
    // necessary if input manually defaults to true by a consuming component
    if (this.isExpanded === true) {
      this.expansionIcon = this.expandLess;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      Object.prototype.hasOwnProperty.call(changes, 'isExpanded') &&
      Object.prototype.hasOwnProperty.call(changes?.isExpanded, 'currentValue')
    ) {
      this.isExpanded = changes?.isExpanded?.currentValue;
      this.expansionIcon = this.isExpanded ? this.expandLess : this.expandMore;
    }
  }

  onExpansionClick(): void {
    if (!this.isAnyDisplayableField) return;
    this.isExpanded = !this.isExpanded;
    this.expansionIcon = this.isExpanded ? this.expandLess : this.expandMore;
    this.emitExpansionButtonEvent.emit();
  }
}
