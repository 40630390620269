<div class="search-container" fxLayout="row" fxLayoutAlign="space-between center">

  <form (ngSubmit)="searchAssets(searchInput.value)" class="search-form" fxFlex>
    <mat-form-field appearance="none" class="map-header-search" fxFlex>
        <zui-icon icon="search" class="search-icon"></zui-icon>
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of options$ | async" [value]="option.value"
                [innerHTML]="option.html" (onSelectionChange)="handleSelectionChange(option)"
                class="search-container-option">
            </mat-option>
        </mat-autocomplete>
        <input 
            matInput
            data-e2e-search-input
            [placeholder]="translateService.get(translations.appAssetSearchForm.placeHolder) | async | translate"
            fxFlex
            [formControl]="searchInput"
            class="search-input"
            [matAutocomplete]="auto"
            minlength="2"
        >
        <mat-error *ngIf="searchInput.hasError('minlength')">
          {{ translateService.get(translations.appAssetSearchForm.errorMsg) | async | translate }}
        </mat-error>
        <zui-icon icon="cancel" matSuffix class="clear-btn"
            [style.visibility]="showClearBtn ? 'visible' : 'hidden'" (click)="resetSearchInput()">cancel
        </zui-icon>
    </mat-form-field>
</form>

    <!-- ZTT-3664 TODO: This button should not be part of the component we uplift to PL. This button should be refactored into the map-header directly, or some other component that makes sense -->
    <button mat-stroked-button class="map-header-filter-btn" fxLayoutAlign="center" (click)="handleFilter()" *ngIf="!hideFilterButton">
        <div class="filter-btn-inner" fxLayout="row" fxLayoutAlign="space-between center">
            <zui-icon class="filter-btn-icon" icon="tune" fxLayoutAlign="center center"></zui-icon>
            <span class="filter-btn-text" fxHide.lt-md>
              {{ translateService.get(translations.appAssetSearchForm.filters) | async | translate }}
            </span>
        </div>
    </button>
</div>
