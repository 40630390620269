import { Component, EventEmitter, Output } from '@angular/core';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { WeatherLayerService } from '@app/modules/location/services/weather-layer.service';

@Component({
  selector: 'app-shared-map',
  templateUrl: './shared-map.component.html',
  styleUrls: ['./shared-map.component.scss']
})
export class SharedMapComponent {
  public isMobile: boolean;

  @Output() mapsAssetsLoadedStateChanged$: EventEmitter<ResourceLoadState> = new EventEmitter<ResourceLoadState>();

  constructor(public locationFacade: LocationFacade, public weatherLayerService: WeatherLayerService) {}

  ngOnInit(): void {
    this.locationFacade.getIsMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  handleAssetsLoadState(state: ResourceLoadState): void {
    this.mapsAssetsLoadedStateChanged$.emit(state);
  }
}
