<ng-container *ngIf="isMobile; then  mobileBlock else desktopBlock;"></ng-container>

<ng-template #desktopBlock>
    <app-map 
        id="map-base" 
        (map$)="locationFacade.receiveMap($event)"
        (mapAssetsLoadState$)="handleAssetsLoadState($event)"
    ></app-map>
    <img
      *ngIf="(weatherLayerService.isWeatherLayerEnabled$ | async)"
      src="assets/leaflet/wx_legend.png"
      alt="Weather Legend"
      style="position: absolute; bottom: 6%; left: 50%; transform: translateX(-50%); z-index: 2000;"
    />
</ng-template>

<ng-template #mobileBlock>
    <app-map 
        id="map-base" 
        class="content-pane mobile-map-pane"
        (map$)="locationFacade.receiveMap($event)"
        (mapAssetsLoadState$)="handleAssetsLoadState($event)"
    ></app-map>
    <img
       *ngIf="(weatherLayerService.isWeatherLayerEnabled$ | async)"
       src="assets/leaflet/wx_legend.png"
       alt="Weather Legend"
       style="position: absolute; bottom: 15%; left: 50%; transform: translateX(-50%); z-index: 2000;"
    />
</ng-template>