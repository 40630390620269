<div class="event-details-container">
  <ul
    class="details-list"
    fxFlex>
    <li fxLayout="row" fxLayoutAlign="space-between">
      <div class="detail-title">Event Time</div> 
      <div class="detail-value">{{ timeStamp }}</div>
    </li>
    <li *ngFor="let eventDetail of eventDetails" fxLayout="row" fxLayoutAlign="space-between">
      <div class="detail-title">{{ eventDetail.displayLabel }}</div>
      <div class="detail-value">{{ eventDetail.value }}</div>
    </li>
  </ul>
</div>


